export default (licenseId, lessonTypeId) => ({
  licenseId,
  lessonTypeId,
  lessonTypes: [],
  productPackageSelect: document.querySelector('#product-packages'),
  lessonTypeSelect: document.querySelector('#lesson-types'),
  subjectSelect: document.querySelector('#lesson-type-subjects'),

  init() {
    // Get lesson type data from the server
    if (this.lessonTypeSelect && this.licenseId) {
      this.getLessonTypes(false)
    }
  },

  // Update available lesson types when selecting/deleting license classes
  changeLicense() {
    if (!this.licenseId && this.lessonTypeSelect) {
      this.lessonTypeSelect.selectize.clearOptions()
      this.subjectSelect.selectize.clearOptions()
      return
    }

    if (this.lessonTypeSelect) {
      this.getLessonTypes()
    }

    this.getProductPackages()
  },

  setEndTime() {
    const endTimeInput = document.querySelector('#end-time')
    if (!endTimeInput.value) {
      endTimeInput._flatpickr.setDate(this.$el.value, true)
    }
  },

  // Modify the available lesson type subjects when selecting a new lesson type
  setLessonSubjects() {
    const $subjectSelect = this.subjectSelect.selectize
    const selectedLessonType = this.lessonTypes.find(({ id }) => id === this.lessonTypeId)
    const availableSubjects = selectedLessonType?.lesson_type_subjects || []

    // Remove selected values that are not available for the selected lesson type
    Object.values($subjectSelect.options).forEach((option) => {
      const subjectId = parseInt(option.value)
      const selectedSubjectAvailable = availableSubjects.some(({ id }) => id === subjectId)
      if (!selectedSubjectAvailable) $subjectSelect.removeItem(subjectId)
    })

    $subjectSelect.clearOptions()

    // Update the selectable values
    availableSubjects
      .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? 0 : 1))
      .forEach(({ id, name }) => $subjectSelect.addOption({ value: id, text: name }))
  },

  // Get lesson types from the server for the selected license class
  getLessonTypes(clearSelectedOption = true) {
    $.ajax({
      url: '/teacher/lesson_types/search',
      data: { license_id: this.licenseId },
      success: (data) => {
        this.lessonTypes = data

        const $lessonTypeSelect = this.lessonTypeSelect.selectize

        if (clearSelectedOption) {
          $lessonTypeSelect.clear()
          this.lessonTypeId = null
        }

        $lessonTypeSelect.clearOptions()

        this.lessonTypes.forEach(({ id, name }) => {
          $lessonTypeSelect.addOption({ value: id, text: name })
        })

        this.setLessonSubjects()
      },
    })
  },

  getProductPackages() {
    $.ajax({
      url: '/teacher/product_packages/available_for_license',
      data: { driving_license_class_id: this.licenseId },
      success: (packages) => {
        const $productPackageSelect = this.productPackageSelect.selectize

        // Remove selected values that are not available for the selected license
        Object.values($productPackageSelect.options).forEach((option) => {
          const packageId = parseInt(option.value)
          const selectedItemAvailable = packages.some((p) => p.id === packageId)
          if (!selectedItemAvailable) $productPackageSelect.removeItem(packageId)
        })

        $productPackageSelect.clearOptions()

        // Update the selectable values
        packages.forEach(({ id, name }) => {
          $productPackageSelect.addOption({ value: id, text: name })
        })
      },
    })
  },
})
