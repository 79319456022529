export default (driverId, productId, activatedFeatureId, activeUntil) => ({
  driverId,
  productId,
  activatedFeatureId,
  activeUntil,

  toggleFeature() {
    const checkbox = this.$el
    checkbox.disabled = true
    loadingSpinner(true)

    checkbox.checked ? this.activate() : this.deactivate()

    checkbox.disabled = false
    loadingSpinner(false)
  },

  async activate() {
    const data = { in_app_purchase_id: productId, driver_id: driverId }

    try {
      const body = JSON.stringify({ account_feature: data })
      const headers = { 'Content-Type': 'application/json' }
      const res = await fetch('/teacher/account_features', { method: 'POST', body, headers })
      if (!res.ok) throw new Error()

      const { account_feature_id, msg, active_until } = await res.json()
      this.activatedFeatureId = account_feature_id
      this.activeUntil = active_until
      flash('notice', msg)
    } catch {
      this.$el.checked = false
      alert(I18n.unknown_error)
    }
  },

  async deactivate() {
    try {
      const body = JSON.stringify({ account_feature: { driver_id: driverId } })
      const headers = { 'Content-Type': 'application/json' }
      const url = `/teacher/account_features/${this.activatedFeatureId}/deactivate`
      const res = await fetch(url, { method: 'PUT', body, headers })
      if (!res.ok) throw new Error()

      const { msg } = await res.json()
      this.activatedFeatureId = null
      this.activeUntil = '--'
      flash('notice', msg)
    } catch {
      this.$el.checked = true
      alert(I18n.unknown_error)
    }
  },
})
