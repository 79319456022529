import type { AlpineComponent } from 'alpinejs'

type CustomModalComponent = { modal: HTMLDialogElement; closeModal: () => void }

export const customModal = (): AlpineComponent<CustomModalComponent> => ({
  modal: null as unknown as HTMLDialogElement,

  init() {
    if (!(this.$root instanceof HTMLDialogElement)) {
      console.error('A <dialog> element is required')
      return
    }

    this.modal = this.$root
    this.modal.showModal()

    // Close the modal when clicking outside it
    // When the target is the modal element, it means that the user clicked on the backdrop
    // If either the mousedown or mouseup event happens inside the modal, the user probably didn't mean to close the modal
    this.modal.addEventListener('mousedown', ({ target: mousedownTarget }) => {
      if (mousedownTarget !== this.modal) return

      this.modal.addEventListener(
        'mouseup',
        ({ target: mouseupTarget }) => mouseupTarget === this.modal && this.closeModal(),
        { once: true }
      )
    })

    this.modal.addEventListener(
      'keydown',
      ({ key }) => {
        if (key === 'Escape') this.closeModal()
      }
    )
  },

  closeModal() {
    this.modal?.remove()
  },
})

type CustomModalLinkComponent = { openModal: (url: string) => Promise<void> }
export type ModalLoadedEvent = { url: string }

// Attach to an <a> tag that links to the modal
export const customModalLink = (): AlpineComponent<CustomModalLinkComponent> => ({
  init() {
    const modalLink = this.$root

    if (!(modalLink instanceof HTMLAnchorElement) || !modalLink.href) {
      console.error('Modal URL missing')
      return
    }

    // This is a fix to a DataTable issue where links on pages 2+ open multiple modals at once
    if (modalLink.getAttribute('linkListenerAttached') === 'true') {
      return
    }
    modalLink.setAttribute('linkListenerAttached', 'true')

    modalLink.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.openModal(modalLink.href).catch((err) => console.error(err))
    })
  },

  async openModal(url: string) {
    window.loadingSpinner(true)

    try {
      const res = await fetch(url)
      const data = await res.text()

      if (!res.ok) {
        throw new Error()
      }

      document.body.insertAdjacentHTML('beforeend', data)

      const event: CustomEvent<ModalLoadedEvent> = new CustomEvent('customModalLoaded', { detail: { url } })
      document.dispatchEvent(event)
    } catch {
      alert('Sorry, something went wrong')
    }

    window.loadingSpinner(false)
  },
})
